// Global css properties from the HMW redesign
:root {
  --color-primary: hsl(223, 70%, 57%);
  --color-secondary: hsl(168, 100%, 25%);
  --color-tertiary: hsl(39, 100%, 38%);
  --color-quaternary: hsl(227, 41%, 29%);
  --color-quinary: hsl(320, 100%, 29%);
  --color-senary: hsl(257, 81%, 64%);
  --color-gray-100: hsl(231, 100%, 99%);
  --color-gray-200: hsl(236, 23%, 87%); // When appearing over black/dark blue bg
  --color-gray-700: hsl(223, 4%, 34%); // When appearing over black/dark blue bg
  --color-text-heading: hsl(229, 60%, 16%);
}

@import 'node_modules/react-md/src/scss/react-md';
@import 'mixins';
@import 'buttons';

// Variable overrides
$md-text-color: red !important;
$md-primary-color: $md-blue-500;
$md-secondary-color: $md-blue-900;

$ag-mat-primary: $md-primary-color;
$ag-mat-accent: $md-secondary-color;

$row-height: 30px;
$header-height: 30px;
$font-size: 14px;
$font-family: Roboto, arial, sans-serif;

.pageTab {
  border: 1px solid white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  height: 40px;
  overflow: hidden;
  box-shadow: 3px -3px #ccc;
  // margin-left: 4px;
  z-index: 1;
}

.pageTab.md-background--secondary {
  z-index: 10;
}

.md-dialog--centered .md-list-tile.md-text {
  height: 25px;
}

.ag-theme-material .ag-header-row .ag-header-cell,
.ag-theme-material .ag-header-row .ag-header-group-cell {
  padding-left: 12px;
  padding-right: 12px;
}
.ag-theme-material .ag-header-cell .ag-floating-filter-button {
  margin-top: 5px;
}

.ag-theme-material .ag-row .ag-cell {
  line-height: $row-height;
  border-left: 1px solid rgb(224, 224, 224);
  padding-left: 12px;
  padding-right: 12px;
}
.ag-theme-material .ag-row .ag-cell.ag-cell-focus {
  border-left: 1px solid $ag-mat-accent;
}

.ag-theme-material .ag-row .ag-cell.ag-cell-inline-editing {
  height: $row-height;
  padding-top: 0;
  padding-bottom: 0;
}

.ag-theme-material .ag-header-row .ag-header-cell,
.ag-theme-material .ag-header-group-cell {
  line-height: $row-height;
  font-size: 13px;
}

*,
button,
h2,
.ag-theme-material,
.ag-theme-material .ag-header {
  font-family: $font-family;
}

.ag-row-odd {
  background: #f7f7f7;
}

.ag-theme-material .ag-row.ag-row-selected {
  background: $md-primary-color;
  color: #fff;
}

.ag-theme-material.column-selector .ag-cell {
  background: #fff;
  color: #000;
  border: none;
}
.ag-theme-material.column-selector .ag-row.ag-row-selected .ag-cell-focus {
  border: none;
}

.ag-theme-material.column-selector .ag-ltr .ag-cell-last-left-pinned {
  border: none;
}

.ag-theme-material.column-selector .ag-row {
  border: none;
}

.ag-theme-material.column-selector .ag-row .ag-cell.ag-cell-focus {
  border: none;
}

.ag-theme-material.column-selector .ag-row.ag-row-selected {
  background: #fff;
  color: #000;
  border: none;
}

.ag-header-cell-label .ag-header-icon.ag-filter-icon {
  display: none;
}

.ag-theme-material .ag-row.ag-row-selected .ag-cell-focus {
  border-width: 2px;
}

.incell-editor {
  border: none;
  background: transparent;
  color: black;
}

.date-editor-input {
  width: 111px;
  height: 28px;
}

// .inline-toolbar,
// .inline-toolbar .md-title--toolbar,
// .inline-toolbar .md-btn--toolbar {
//   height: 45px;
//   line-height: 45px;
// }

.inline-toolbar .md-btn--toolbar {
  // margin: -7px 0 0 0;
}

.inline-toolbar .md-btn--toolbar .md-btn {
  margin: auto;
}

.my-slider .md-slider-track-fill {
  background: none;
}

.md-list--nested-1 .md-list-tile {
  padding-left: 40px;
}

.CodeMirror pre.CodeMirror-line span {
  font-family: Consolas, 'Courier New', Courier, monospace;
}

.iframe-loading {
  display: none;
}

.iframe-loaded {
  display: block;
}

.loadingIndicator {
  background: url(../images/loading.gif);
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.react-datepicker.myDateSelector {
  font-size: 1em;
}
.myDateSelector {
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1.1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
  .react-datepicker__month-select {
    font-size: 1em;
  }
  .react-datepicker__year-select {
    font-size: 1em;
  }
}

/* HMW Redesign */
@import 'redesign';
// .menu__submenu.active.open {
//   @include curveOuter(var(--color-primary), white, 40, #d1d5db);
// }

.hamburger {
  display: block;
  width: 3rem;
  height: 3rem;

  .line {
    fill: none;
    stroke: var(--color-gray-100);
    stroke-width: 6px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  /* Hamburger */
  .line.top,
  .line.bottom {
    stroke-dasharray: 50px 600px;
    stroke-dashoffset: 0px;
  }

  .line.cross {
    stroke-dasharray: 50px 60px;
    stroke-dashoffset: 0px;
  }

  /* Cross */
  &.on .line.top,
  &.on .line.bottom {
    stroke-dasharray: 70.71px 600px;
    stroke-dashoffset: -392px;
  }

  &.on .line.cross {
    stroke-dasharray: 50px 60px;
    stroke-dashoffset: 51px;
  }

  /* Timing */
  $timing: 0.35s;

  .line.cross {
    transition: $timing stroke-dasharray ease $timing,
      $timing stroke-dashoffset ease $timing, $timing stroke ease 0s;
  }

  .line.top {
    transition: ($timing * 1.5) stroke-dasharray ease 0s,
      ($timing * 1.5) stroke-dashoffset ease 0s, $timing stroke ease 0s;
  }

  .line.bottom {
    transition: ($timing * 1.5) stroke-dasharray ease $timing,
      ($timing * 1.5) stroke-dashoffset ease $timing, $timing stroke ease 0s;
  }

  &.on .line.cross {
    transition: $timing stroke-dasharray ease 0s, $timing stroke-dashoffset ease 0s,
      $timing stroke ease 0s;
  }
}
