.btn {
  @apply p-3 px-6 rounded-xl border-2 whitespace-nowrap flex items-center;

  &--primary {
    @apply text-light bg-primary;

    [class*='icon'] {
      @apply text-light;
    }
  }

  &--secondary {
    @apply text-gray-500 border-gray-100 justify-center bg-white;

    [class*='icon'] {
      @apply text-primary;
    }
  }

  [class*='icon'] {
    @apply inline-block ml-6 w-5 h-5;
  }

  &.icon-only {
    @apply text-light bg-primary;

    [class*='icon'] {
      @apply ml-0 text-light bg-primary;
    }
  }

  &.secondary-icon-only {
    [class*='icon'] {
      @apply ml-0;
    }
  }
}

.btn:hover {
  filter: brightness(110%);
  /* Increase brightness by 10% */
}

.select:hover {
  filter: brightness(110%);
  /* Increase brightness by 10% */
}

.button {
  background-color: #06559d;
  color: white;
  width: calc(60% - 50px);
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 0.75rem;
  border-width: 2px;
  padding: 0.75rem 1.5rem;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  background-image: none;
  -webkit-appearance: button;
  text-transform: none;
  box-sizing: border-box;
}


.md-file-input-container {
  label {
    padding: 0.75rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    box-shadow: none !important;
    border-radius: 0.75rem !important;
    margin-top: 0 !important;

    &.md-background--primary {
      background: var(--color-primary);
    }
  }
}