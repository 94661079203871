@import 'globals';
@import 'fonts';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');
// @include host-google-font(Roboto, 400);
@include host-material-icons();
@include react-md-everything;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}

#root > div {
  overflow: hidden;
}

#image-logo {
  margin: 0 auto !important;
}

.ag-theme-material .ag-icon-checkbox-unchecked {
  background-size: 10px 10px !important;
}
.ag-theme-material .ag-selection-checkbox span {
  top: 0px !important;
}

.md-toolbar {
  @apply h-28 items-center bg-light;
}

@media screen and (min-width: 768px) {
  .md-navigation-drawer-content {
    height: calc(100vh - 45px);
  }
  .md-toolbar .md-btn--text {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .md-toolbar-relative {
    margin-top: 45px;
  }
  .md-title--toolbar {
    line-height: 50px;
  }
  .md-btn--toolbar {
    margin-top: 5px;
  }
}

@media screen and (min-width: 1025px) {
  .md-navigation-drawer-content {
    height: calc(100vh - 45px);
  }
  .md-toolbar .md-btn--text {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .md-toolbar-relative {
    margin-top: 45px;
  }
  .md-title--toolbar {
    line-height: 50px;
  }
  .md-btn--toolbar {
    margin-top: 5px;
  }
}

.hl {
  background-color: #ffff00;
  color: #000;
}

/**
* css for Promotions
**/
.availableDateTime {
  display: flex;
  align-items: center;
  margin: 10px 0px;

  .availabilityInput {
    margin-right: 5px;
  }

  .dayActive,
  .dayName {
    width: 59px;
    height: 50px;
    text-align: center;
    // border-right:1px solid #c0c0c0;
    // border-bottom: 1px solid #c0c0c0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dayTime {
    position: relative;
    height: 50px;
    // border-bottom: 1px solid #c0c0c0;
    // border-right:1px solid #c0c0c0;
  }

  .md-slider-track {
    background: #2196f3;
  }
}

.availabilityRowHeader {
  display: flex;

  .dayActiveHeader,
  .dayNameHeader {
    // border-right: 1px solid #c0c0c0;
    // border-bottom: 1px solid #c0c0c0;
    width: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    font-weight: bold;
  }

  .dayTimeHeader {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0px;
    // border-bottom: 1px solid #c0c0c0;
    // border-right: 1px solid #c0c0c0;
  }
}

.swatches-picker > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
  overflow-y: auto !important;
}

.gridColourEditor > div > div > .material-picker {
  width: 520px !important;
  height: 120px !important;
}

.gridColourEditor > div > div > .material-picker > .flexbox-fix > div {
  width: 35px;
}

.toolbarCheckbox label.md-text {
  color: #000;
}

#stockcode {
  color: #000;
}

.GRID-EXPANDER {
  position: absolute;
  top: 30px;
  width: 100%;
  height: calc(100% - 30px);
  left: 0;
  overflow: auto;
}

.react-codemirror2 {
  height: calc(100% - 50px);
}
.dropFilterList > li > div > div.md-tile-content {
  flex-shrink: 0;
}
.dropFilterList > li > div {
  height: 30px;
  padding-right: 32px;
}
.dropFilterList > li > div > div > div {
  overflow-y: unset;
  text-overflow: unset;
  width: 100%;
}

#posPreferences {
  .ag-cell-label-container {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
  }

  .ag-header-cell-label {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
    padding: 0 !important;
    overflow: visible;
  }

  .ag-header-cell-label .ag-header-cell-text {
    /* Force the width corresponding at how much width
      we need once the text is laid out vertically */
    width: 30px;
    transform: rotate(-45deg);
    margin-left: 50px;
    margin-top: 100px;
    /* Since we are rotating a span */
    display: inline-block;
    float: none;
    overflow: visible;
  }

  .ag-theme-material .ag-header-cell-menu-button .ag-icon-menu {
    display: none;
  }
}

/* Report controls */

.criteria {
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: flex-start;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0.5rem;
}

.criterion {
  border: 2px solid var(--color-quaternary);
  width: calc(100% - 0.5rem);
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  color: black;
  flex-wrap: wrap;
  padding: 0.1rem 0.5rem;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}
.criterion-col > .criterion {
  margin-bottom: unset;
}

.criterion-col > .criterion:last-child,
.criterion-col > .criterion {
  border: unset;
}

.criterion > div {
  display: flex;
}

.criterion .criterion-title-col {
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 0.5rem;
}
.criterion-small .criterion-title-col h3 {
  color: black;
  text-align: center;
  margin-bottom: 1rem;
}

.criterion-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex: 9;
}

.journal__header-col-row > .criterion-row {
  height: 20px;
  flex-wrap: nowrap;
}

.journal__header-col-row > .criterion-row > * > .to-col > span {
  width: 50px;
}

.criterion-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* date range selector and button selector */

.selection-label {
  margin-right: 0.5rem;
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.range-select,
.button-col {
  flex: 1 1;
  padding: 0.1rem;
  min-width: 11rem;
  max-width: fit-content;
}

.range-select-max {
  max-width: 11rem;
}

.range-select > div {
  border-radius: 10px;
}

.button-col {
  margin-right: 1rem;
}

.button-col button {
  width: 100%;
}

.sort-col {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  max-width: 7rem;
  min-width: 7rem;
}

.sort-col > span {
  display: block;
}

.date-value-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;
  flex: 6;
}

.from-col,
.to-col {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  min-height: 2.5rem;
  flex-wrap: wrap;
}

.max-period {
  padding-top: 20;
  color: #ff0000;
}

.from-col > span,
.to-col > span {
  margin-right: 0.5rem;
}

.react-datepicker-wrapper {
  flex: 1;
  min-height: 2.5rem;
  width: fit-content;
  display: flex !important;
  align-items: center;
}

.selector-btn {
  border-color: rgb(211, 213, 218);
}

.react-datepicker__input-container {
  margin-right: 0.5rem;
  width: fit-content;
}

.react-datepicker-wrapper input {
  width: 100%;
  min-width: 7rem;
  max-width: 10rem;
}

.time-col {
  justify-content: flex-start;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  min-width: 10rem;
}

.time-col-heading {
  padding-right: 0.5rem;
}

.time-col > input {
  max-width: 2rem;
}

.criterion::after {
  content: '';
  position: absolute;
  z-index: -1;
  height: calc(100% - 20px);
  top: 10px;
  right: -1px;
  width: 5px;
  background: #666;
}

.criterion input {
  color: grey;
  border-radius: 5px;
  padding: 0.1rem;
  outline: none;
  border: 1px solid rgb(211, 213, 218);
  text-align: center;
}

.criterion input:focus {
  border: 2px solid black;
}

/* option selector */

.criterion .md-list {
  display: flex;
  flex-wrap: wrap;
}

.criterion-list-item {
  height: 30px;
  display: flex;
  align-items: center;
  min-width: 15rem;
}

.criterion-sort-item {
  height: 30px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.criterion .md-text {
  font-size: 12px;
}

.criterion .md-tile-content--left-button {
  padding-left: 0.1rem;
}

.criterion .md-tile-content--right-padding {
  padding-right: 0.1rem;
}

.criteria::-webkit-scrollbar {
  display: none;
}

.criterion::-webkit-scrollbar {
  display: none;
}

.criterion::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.criterion::-webkit-scrollbar-thumb {
  background-color: blue;
  outline: 1px solid blue;
}

.criterion .md-list {
  background: transparent;
}

.criterion .md-text,
.criterion h3 {
  color: black;
}

.criterion .md-text--secondary {
  color: rgb(211, 213, 218);
}

.journalTable {
  @include react-md-make-fixed-table(true, 25px);
}

.toolbarButtonStyle {
  transition: 'background 0.15s, box-shadow 0.3s, color 0.15s';
}

.toolbarButtonStyle:hover {
  background-color: rgba(153, 153, 153, 0.12);
}

.md-dialog-content--padded {
  overflow-y: hidden;
}

.customScroll::-webkit-scrollbar {
  display: block;
  width: 3px;
}

.customScroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
}

/* import button text */
.fileupload span.md-icon-text {
  color: #6b7280 !important;
  font-weight: 400;
}

/* Record Count */
.recordLabel {
  font-family: inherit;
  font-size: 0.8rem;
}

/* tabs */

.tabs-container {
  width: 100%;
}

.tabs {
  border-bottom: 1px solid black;
  background-color: var(--color-gray-100);
  flex: 1;
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 1rem;
  height: 3rem;
}

.paginate {
  max-width: 2rem;
  cursor: pointer;
  color: var(--color-primary);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginate > span {
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.paginate--end {
  position: absolute;
  right: 1rem;
  top: 10px;
}

.tab-wrapper {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  flex: 1;
  position: absolute;
  left: 0.5rem;
  right: 0.5rem;
}

.tab {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  max-width: fit-content;
  height: 3rem;
  overflow: hidden;
  margin-right: 1px;
  font-size: 1rem;
  color: white;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  position: relative;
  background: var(--color-primary);
  padding: 0.1rem 0.1rem 0 0.2rem;
}

.tab-active {
  background: var(--color-quaternary);
  width: auto !important;
  min-width: fit-content;
}

.tab-label-container {
  flex: 5;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.tab-active > .tab-label-container > span {
  margin-right: 1rem;
}

.tab-label-container > span {
  text-align: left;
  font-weight: bold;
  letter-spacing: 0.8px;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tab-icon-container {
  flex: 1;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 15px;
  position: absolute;
  top: 3px;
  right: 3px;
}

.tab-close-icon {
  font-size: 1rem !important;
  color: white;
  cursor: pointer;
}

.tab-active > .tab-icon-container {
  display: flex;
}

.tab-content {
  width: 100%;
}

.active-content {
  display: block;
  width: 100%;
}

.non-active-content {
  display: none;
}

.swatches-picker > div:nth-child(1) > div:nth-child(1) {
  box-shadow: none !important;
}

/* Tooltip Styles */

.tooltip {
  z-index: 1;
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  display: none;
  position: fixed;
  padding: 0.5rem;
  transform: translate(0, -55px);
  white-space: nowrap;
  border-radius: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.8px;
  z-index: 10;
}

.tip {
  background-color: white;
  border: 1px solid var(--color-primary);
  width: 20px;
  height: 20px;
  position: fixed;
  transform: translate(52%, -41px) rotate(45deg);
  z-index: 5;
  display: none;
}

.tab:hover .tooltip {
  display: block;
}

.tab:hover .tip {
  display: block;
}

/* Dialog */

.md-background--card {
  border-radius: 0.75rem;
}

.snackbar {
  border: 1px solid #c0c0c0;
  border-radius: 50px;
  justify-content: center;
  background-color: #F7F7F7;
  color:#000;
  bottom: 20px;
  font-size: 17px;
}