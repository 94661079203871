.gradient-primary {
  @apply from-blue-400 to-primary;
}
.gradient-secondary {
  @apply from-green-400 to-secondary;
}
.gradient-tertiary {
  @apply from-yellow-400 to-tertiary;
}
.gradient-quaternary {
  @apply from-purple-400 to-quaternary;
}
.gradient-quinary {
  @apply from-pink-400 to-quinary;
}
.gradient-senary {
  @apply from-purple-400 to-senary;
}

.ag-header-row:first-child {
  @apply text-quaternary bg-gray-200;
}

.ag-header-row:last-child {
  @apply bg-gray-100;
}

.ag-icon {
  @apply text-primary;
}

.ag-pinned-left-cols-viewport {
  .ag-cell {
    background: linear-gradient(to top, #dfe1ef, #e9eaf8);
    @apply text-dark;
  }
  .ag-row {
    &:hover {
      .ag-cell {
        background: linear-gradient(to bottom, #bdc2d3, #c3c7d9);
      }
    }
  }
}
.ag-pinned-left-cols-viewport .ag-row.ag-row-selected .ag-cell,
.ag-theme-material .ag-row.ag-row-selected {
  background-image: none;
  @apply bg-primary text-white;
}

.text-light .md-tile-text--primary {
  color: var(--color-gray-100);
}

.top-level-nav {
  .top-level-nav__item {
    @apply rounded-l-xl;
    > a {
      @apply flex items-center text-light h-12 px-0;
      &.md-list-tile--active {
        background: transparent !important;
      }
      .md-tile-addon--icon {
        @apply h-auto relative z-10;
      }
      .md-tile-content {
        @apply p-0 pl-4;
      }
    }
    &.is-active,
    &.is-active-dashboard {
      @apply relative;
    }
    &.is-active-dashboard {
      @apply bg-white;
      .md-tile-text--primary {
        color: var(--color-gray-700);
      }
      @include curveOuter(var(--color-quaternary), white, 40);
    }
    &.is-active {
      background: var(--color-primary);
      @include curveOuter(var(--color-quaternary), var(--color-primary), 40);
    }
  }
}

.menu > li {
  position: relative;
  z-index: 10;
  &:hover {
    background: inherit;
  }
}

.menu > li.active {
  background: rgb(209, 213, 219);
  padding: 6px 0;
  box-shadow: inset 0 0 0 6px rgb(209, 213, 219);
  color: inherit;
  border-bottom-right-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  z-index: 5;
  &:hover {
    background: gray;
  }
}

.menu:not(.menu__submenu) > li.active {
  box-shadow: inset 0 0 0 6px white;
  border-bottom-right-radius: unset;
  border-top-right-radius: unset;
  @apply rounded-l-xl;
  @include curveOuter(var(--color-primary), white, 40);
}

.hidesidebar {
  position: absolute;
  left: -1000px;
  z-index: 10;
  height: 100vh;
}

.showsidebar {
  position: absolute;
  z-index: 10;
  height: 100vh;
}

.hidesidebar:hover {
  left: unset;
}

.mainMenu:hover + div div .hidesidebar {
  left: unset;
}

.topnavlabel {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  bottom: -32px;
  z-index: 20;
  left: 32px;
}
.top-level-nav__item:hover a .topnavlabel {
  visibility: visible;
}
